
body {
  margin: 0;
  font-family: var(--base-font);
  font-family:var(--base-font, 'Verdana');
  font-size: var(--base-font-size, 20px);
  font-weight: var(--base-font-w, normal);
  line-height: 1.5;
  color: var(--text);
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

b,
strong {
    font-family:var(--base-font-strong, 'Verdana');
    font-weight:var(--base-font-strong-w, bold);
}

h1, h2, h3, h4, h5, h6 {
  margin-top: .5rem;
  margin-bottom: 1rem;
  font-family: var(--accent-font, 'Garamond');
  font-weight: var(--accent-font-w, normal);
  line-height: 1.2;
  color: inherit;
}

h1 strong, h2 strong, h3 strong, h4 strong, h5 strong, h6 strong {
  font-family:var(--accent-font-strong, 'Garamond');
  font-weight:var(--accent-font-strong-w, bold);
}

h1 {
  font-size: 2.0rem;
}

h2 {
  font-size: 1.75rem;
}

h3 {
  font-size: 1.50rem;
}

h4 {
  font-size: 1.25rem;
}

h5 {
  font-size: 1rem;
}

h6 {
  font-size: 1rem;
}

p {
  margin-top: 0;
  margin-bottom: .9rem;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}
