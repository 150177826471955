@tailwind base;
@tailwind components;
@tailwind utilities;

@import url('https://fonts.googleapis.com/css2?family=Russo+One&family=Source+Sans+3:ital,wght@0,300;0,700;1,300;1,700&display=swap');

:root {
	/* Colors */
	--primary: #c14953ff;
	--secondary: #e5dcc5ff;
	--accent: #848fa5ff;
    --auxiliary: #4c4c47ff;

	--jet: #2d2d2aff;
	--davys-gray: #4c4c47ff;
	--cool-gray: #848fa5ff;
	--bittersweet-shimmer: #c14953ff;
	--pearl: #e5dcc5ff;

	--text: #2d2d2aff;

	--grey: #4c4c4c;
	--light-grey: #c6c6c6;

    /* Fonts */
	--base-font: 'Source Sans 3', sans-serif;
	--base-font-strong : 'Source Sans 3', sans-serif;
	--base-font-w : 300;
	--base-font-strong-w: 700;

	--accent-font: 'Russo One', sans-serif;
	--accent-font-strong : 'Russo One', sans-serif;
	--accent-font-w: 400;
	--accent-font-strong-w: 400;

	--base-font-size: 20px;

	/* Animation Speed */
	--default-transition: all 300ms ease-in-out;
}

.text-primary {
    color:var(--primary);
}
.text-secondary {
    color:var(--secondary);
}
.text-accent {
    color:var(--accent);
}
.text-auxiliary {
    color:var(--auxiliary);
}
.text-grey {
    color:var(--grey);
}
.text-light-grey {
    color:var(--light-grey);
}

.bg-primary {
    background-color:var(--primary);
}
.bg-secondary {
    background-color:var(--secondary);
}
.bg-accent {
    background-color:var(--accent);
}
.bg-auxiliary {
    background-color:var(--auxiliary);
}
.bg-grey {
    background-color:var(--grey);
}
.bg-light-grey {
    background-color:var(--light-grey);
}
